import React from 'react';
import IneligibleQuoteErrorPage from 'templates/ErrorPage/500-ineligible-quote';
import MinMaxPremiumErrorPage from 'templates/ErrorPage/500-min-max-premium';
import { ErrorType } from 'state/error/actions';

type IneligibleQuotePageProps = {
  location: {
    state?: { errorType: ErrorType };
  };
};

/* istanbul ignore next */
const IneligibleQuotePage: React.FC<IneligibleQuotePageProps> = ({
  location: { state },
}) => {
  switch (state?.errorType) {
    case ErrorType.MIN_MAX_ERROR:
      return <MinMaxPremiumErrorPage />;
    case ErrorType.QUOTE_INELIGIBLE:
    default:
      return <IneligibleQuoteErrorPage />;
  }
};

export default IneligibleQuotePage;
